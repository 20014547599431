// Video
const video3D = "https://www.youtube.com/watch?v=sLZK0UmGYXI";

// Text
const company = "SEARAY"
const model = "400SLX"
const text = "3D configurator to help your<br>clients feel your product like<br>their own."

// Button text
const buttonText = "See our work";

export {
    video3D,
    company,
    model,
    text,
    buttonText
};